<template>
  <navigation index="storeSupplier" ref="navigation">
    <div class="topnavigation" style="margin: 5px 0px">
      <el-button type="primary" @click="OpenStoreSupplierWindow({})">
        {{ tools.GetLanguageValue("web.新增") }}</el-button
      >
    </div>
    <div>
      <div :style="{ height: ContentHeight + 'px' }">
        <el-table
          border
          :data="StoreSupplierDatas"
          style="width: 100%; height: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column
            :label="tools.GetLanguageValue('web.名称')"
            width="180"
            prop="Name"
          />
         
          <el-table-column
            :label="tools.GetLanguageValue('web.备注')"
            width="180"
            prop="Desc"
          /> 
          <el-table-column :label="tools.GetLanguageValue('web.操作')">
            <template #default="scope">
              <el-button
                type="success"
                size="small"
                @click="OpenStoreSupplierWindow(scope.row)"
              >
                {{ tools.GetLanguageValue("web.编辑") }}</el-button
              >
              <el-button
                size="small"
                @click="DeleteStoreSupplier(scope.row)"
                type="danger"
              >
                {{ tools.GetLanguageValue("web.删除") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div
          style="
            height: 50px;
            display: flex;
            justify-items: center;
            gap: 10px;
            padding: 0px 10px;
          "
        >
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="CurrentChange"
            :page-size="StoreSupplierPadding.Size"
            :total="StoreSupplierPadding.Count"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 添加 -->
      <el-dialog
        v-model="StoreSupplierWindow"
        :title="tools.GetLanguageValue('web.编辑')"
        width="800px"
        :show-close="false"
      >
      <el-form
              ref="form"
              :model="ProductBrandOpt"
              label-width="80px"
              style="margin-top: 10px"
            >
              <el-form-item :label="tools.GetLanguageValue('web.名称')">
                <el-input
                  v-model="StoreSupplierOpt.Name"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入品牌名称')"
                ></el-input>
              </el-form-item> 
              <el-form-item :label="tools.GetLanguageValue('web.备注')">
                <el-input
                  v-model="StoreSupplierOpt.Desc"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入备注')"
                ></el-input>
              </el-form-item>
            </el-form>
        <!-- <el-tabs v-model="LanguageName">
          <el-tab-pane :label="tools.GetLanguageValue('web.默认')" name="first">
            <el-form
              ref="form"
              :model="ProductBrandOpt"
              label-width="80px"
              style="margin-top: 10px"
            >
              <el-form-item :label="tools.GetLanguageValue('web.名称')">
                <el-input
                  v-model="StoreSupplierOpt.Name"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入品牌名称')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.编号')">
                <el-input
                  v-model="StoreSupplierOpt.Code"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入编号')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.排序')">
                <el-input
                  v-model="StoreSupplierOpt.Sort"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入排序')"
                ></el-input>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.备注')">
                <el-input
                  v-model="StoreSupplierOpt.Description"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入备注')"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        
        </el-tabs> -->

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="StoreSupplierWindow = false">
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button @click="InsertStoreSupplier" type="primary">
              {{ tools.GetLanguageValue("web.保存") }}</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </navigation>
</template>
  <script >
import axios from "../../../../commons/AxiosMethod.js";
import tools from "../../../../commons/tools.js";

import { ElMessageBox, ElMessage } from "element-plus";
import ProductItem from "../../../../components/ProductItem.vue";
import navigation from "../../../../components/Modules/Navigation.vue";
import { CloseBold, Plus } from "@element-plus/icons-vue";
export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      LanguageName: "first",
      StoreSupplierOpt: {},
      StoreSupplierWindow: false,
      StoreSupplierDatas: [],
      StoreSupplierPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      ContentHeight: 800,
      tools: tools,
    };
  },
  name: "App",
  mounted() {
    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    this.GetStoreSupplier();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 130;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 130;
  },

  components: {
    navigation,
    ProductItem,
    CloseBold,
    Plus,
  },
  methods: {
    // 上传成功操作
    UploadSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.ProductBrandOpt.LogoFormat = response.Data.ImageFormet;
        this.ProductBrandOpt.Logo = response.Data.FileName;
      }
    },

    InputSelect(e) {
      e.currentTarget.select();
    },
    //查询品牌列表
    GetStoreSupplier() {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/GetStoreSupplier",
        "get",
        {},
        (result) => {
            this.StoreSupplierDatas = result.Data;
         
        }
      );
    },

    //删除 品牌
    DeleteStoreSupplier(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/GoodsManage/DeleteStoreSupplier",
            "get",
            {
              id: item.Id,
            },
            (result) => {
              if (result.Data.IsSuccess) {
                this.StoreSupplierWindow = false;
                this.GetProductBrands();
              } else {
                ElMessageBox({
                  title: "提示",
                  message: result.Data.Msg,
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {});
    },

    //打开编辑窗口
    OpenStoreSupplierWindow(item) {
      this.StoreSupplierOpt = item;
      this.StoreSupplierWindow = true;
      // axios.apiMethod(
      //   "/stores/GoodsManage/GetProductBrandDetail",
      //   "get",
      //   { id: item.Id },
      //   (result) => {

      //     this.ProductBrandOpt = result.Data;
      //     this.StoreSupplierWindow = true;

      //   }
      // );
    },

    //插入品牌
    InsertStoreSupplier() {
      axios.apiMethod(
        "/stores/GoodsManage/InsertStoreSupplier",
        "post",
        this.StoreSupplierOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.StoreSupplierWindow = false;
            this.GetStoreSupplier();
          } else {
            ElMessageBox({
              message: result.Msg,
              type: "error",
            });
          }
        }
      );
    },
  },
};
</script>
  
  <style scoped lang="less">
.inputSKU {
  width: 100%;
  border: 0px;
}

.tableSKU {
  tr {
    td {
      padding: 5px;
      height: 40px;
    }
  }
}
</style>
  